require('./bootstrap');

import Alpine from 'alpinejs';

window.Alpine = Alpine;

Alpine.start();

document.addEventListener('DOMContentLoaded', e => {
    $('#none_fan').autocomplete()
}, false);

$(document).on("click", "#bars", function(){
    $("#sidemenu").removeClass("d-none");
    $("#bars").addClass("close-sidemenu");
});

$(document).on("click", ".close-sidemenu", function(){
    $("#sidemenu").addClass("d-none");
    $("#bars").removeClass("close-sidemenu");
});
